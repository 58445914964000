import "typeface-montserrat";
import "typeface-open-sans";
import './src/styles/tailwind.css';

import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faPhone,
} from "@fortawesome/free-solid-svg-icons";

library.add(
    faPhone,
    );